<template>
    <div v-for="(d,index) in 3" :key="index" class="insurance-item">
        <div class="insurance-item-head row">
            <h2 class="insurance-item-head-name p-0 mb-0">
                <ContentLoader width="300" height="20">
                    <rect x="0" y="0" width="300" height="20" rx="10" ry="10" />
                </ContentLoader>
            </h2>
            <div class="insurance-item-head-btn">
                <ContentLoader width="100" height="30" primary-color="#B4B7BF" secondary-color="#D4D6DA">
                    <rect x="0" y="0" width="100" height="30" rx="10" ry="10" />
                </ContentLoader>
            </div>
        </div>
        <div class="insurance-item-content">
            <div class="row insurance-item-content-body mt-3">
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                    <div class="check-info-content-body-head mb-2">
                        <ContentLoader width="130" height="20">
                            <rect x="0" y="0" width="130" height="20" rx="10" ry="10" />
                        </ContentLoader>
                    </div>
                    <div class="insurance-item-content-body-text">
                        <ContentLoader width="100" height="20">
                            <rect x="0" y="0" width="100" height="20" rx="10" ry="10" />
                        </ContentLoader>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                    <div class="check-info-content-body-head mb-2">
                        <ContentLoader width="130" height="20">
                            <rect x="0" y="0" width="130" height="20" rx="10" ry="10" />
                        </ContentLoader>
                    </div>
                    <div id="policyNumber" class="insurance-item-content-body-text">
                        <ContentLoader width="100" height="20">
                            <rect x="0" y="0" width="100" height="20" rx="10" ry="10" />
                        </ContentLoader>
                        <span class="ms-2">
                            <ContentLoader width="20" height="20">
                                <rect x="0" y="0" width="20" height="20" rx="10" ry="10" />
                            </ContentLoader>
                        </span>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                    <div class="check-info-content-body-head mb-2">
                        <ContentLoader width="150" height="20">
                            <rect x="0" y="0" width="150" height="20" rx="10" ry="10" />
                        </ContentLoader>
                    </div>
                    <div class="insurance-item-content-body-text">
                        <ContentLoader width="150" height="20">
                            <rect x="0" y="0" width="150" height="20" rx="10" ry="10" />
                        </ContentLoader>
                    </div>
                </div>
            </div>
            <div class="row justify-content-between align-items-center insurance-item-content-body mt-3">
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-9 col-xl-9 row">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 me-2">
                        <div class="check-info-content-body-head mb-2">
                            <ContentLoader width="100" height="20">
                                <rect x="0" y="0" width="100" height="20" rx="10" ry="10" />
                            </ContentLoader>
                        </div>
                        <div class="insurance-item-content-body-text">
                            <ContentLoader width="120" height="20">
                                <rect x="0" y="0" width="120" height="20" rx="10" ry="10" />
                            </ContentLoader>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <div class="check-info-content-body-head mb-2">
                            <ContentLoader width="150" height="20">
                                <rect x="0" y="0" width="150" height="20" rx="10" ry="10" />
                            </ContentLoader>
                        </div>
                        <div class="insurance-item-content-body-text">
                            <ContentLoader width="200" height="20">
                                <rect x="0" y="0" width="200" height="20" rx="10" ry="10" />
                            </ContentLoader>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 d-flex justify-content-end">
                    <ContentLoader width="100" height="40" primary-color="#B4B7BF" secondary-color="#D4D6DA">
                        <rect x="0" y="0" width="100" height="40" rx="10" ry="10" />
                    </ContentLoader>
                </div>
            </div>
        </div>
    </div>
</template>
  