<template>
    <div class="d-flex justify-content-center">
        <div class="empty col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
            <div class="empty-img">
                <img src="@/assets/images/empty.svg" alt="empty" />
            </div>
            <div class="empty-body">
                <h2 class="empty-title">Müqaviləniz yoxdur</h2>
                <p class="empty-text">Sığorta almaq üçün ”Sığorta al”
                    düyməsinə klikləyin</p>
            </div>
        </div>
    </div>
</template>